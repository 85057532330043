<template>
  <div>
    <a-row style="width: 100%">
      <a-col class="aside iss-main iss-main-mr" :span="6">
        <div>
          <a-row>
            <div class="header pt-30">
              <a-avatar
                :size="128"
                :src="contactdetail.headImgUrl"
                v-if="contactdetail.headImgUrl"
              >
              </a-avatar>
              <a-avatar :size="128" v-else>
                <template #icon><UserOutlined /> </template>
              </a-avatar>
              <div class="mt-5">
                {{ contactdetail.fullName }}
                <img
                  src="../../../assets/images/male.png"
                  v-if="contactdetail.gender == '0'"
                />
                <img
                  width="20"
                  src="../../../assets/images/female.png"
                  v-if="contactdetail.gender == '1'"
                />
              </div>
              <!--          <div class="mt-5">-->
              <!--            线索评分:<span>{{ contactdetail.score }}</span>-->
              <!--          </div>-->
            </div>
          </a-row>
          <a-row>
            <div class="information mt-20 ml-20">
              <a-descriptions
                title="基本信息"
                :labelStyle="{
                  justifyContent: 'flex-end',
                  width: '320px',
                  minWidth: '50px',
                  textAlign: 'end',
                }"
                :contentStyle="{
                  width: '320px',
                  minWidth: '150px',
                  textAlign: 'left',
                }"
              >
                <a-descriptions-item span="3">
                  <span>公司：</span>
                  {{ contactdetail.company }}
                </a-descriptions-item>
                <a-descriptions-item span="3">
                  <span>部门：</span>
                  {{ contactdetail.department }}
                </a-descriptions-item>
                <a-descriptions-item span="3">
                  <span>职位：</span>
                  {{ contactdetail.position }}
                </a-descriptions-item>
                <a-descriptions-item span="3">
                  <span>行业：</span>
                  {{ contactdetail.industryLv1Code }}
                </a-descriptions-item>

                <a-descriptions-item span="3">
                  <span>省份：</span>
                  {{ contactdetail.province }}
                </a-descriptions-item>
                <a-descriptions-item span="3">
                  <span>城市：</span>
                  {{ contactdetail.city }}
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </a-row>
          <a-row>
            <a-col>
              <div class="contact mt-10 ml-20">
                <a-descriptions
                  title="联系方式"
                  :labelStyle="{
                    justifyContent: 'flex-end',
                    width: '120px',
                    minWidth: '50px',
                    textAlign: 'end',
                  }"
                  :contentStyle="{
                    width: '200px',
                    minWidth: '150px',
                    textAlign: 'left',
                  }"
                >
                  <a-descriptions-item span="3">
                    <span>手机号：</span>
                    {{ contactdetail.mobileNum }}</a-descriptions-item
                  >
                  <a-descriptions-item span="3">
                    <span>邮箱：</span>
                    {{ contactdetail.email }}</a-descriptions-item
                  >
                </a-descriptions>
              </div>
            </a-col>
          </a-row>
          <a-row class="iss-tab">
            <a-col class="content iss-card" :span="23">
              <div>
                <a-row :gutter="16">
                  <a-col :span="24">
                    <a-card
                      title="标签"
                      size="small"
                      :bordered="true"
                      headStyle="color:#5979f8; font-weight:bold; font-size:16px"
                    >
                      <div>
                        <template
                          v-for="(item, index) in normalTag"
                          :key="item.tagName"
                        >
                          <span class="tag-content">
                            <a-tag :color="colors[index % 4]">
                              {{ item.tagName }}
                            </a-tag>
                          </span>
                        </template>
                      </div>
                    </a-card>
                  </a-col>
                </a-row>
                <a-row :gutter="16">
                  <a-col :span="24">
                    <a-card
                      title="自定义标签"
                      size="small"
                      :bordered="true"
                      headStyle="color:#5979f8; font-weight:bold; font-size:16px"
                    >
                      <div>
                        <template
                          v-for="(item, index) in customTag"
                          :key="item.tagName"
                        >
                          <span class="tag-content">
                            <a-tag :color="colors[index % 4]">
                              {{ item.tagName }}
                            </a-tag>
                            <span class="tag-delete"
                              ><a-popconfirm
                                :key="item.id"
                                title="是否确认删除"
                                ok-text="确认"
                                cancel-text="取消"
                                @confirm="popConfirmByDelete(item)"
                                @cancel="popCancelByDelete(item)"
                                @visibleChange="visibleChange"
                              >
                                <DeleteOutlined
                                  style="
                                    color: #ff4d4f;
                                    margin-left: 10px;
                                  " /></a-popconfirm
                            ></span>
                          </span>
                        </template>
                        <a-input
                          v-if="inputVisible"
                          ref="inputRef"
                          v-model:value="inputValue"
                          type="text"
                          size="small"
                          :style="{ width: '78px' }"
                          @blur="handleInputConfirm"
                          @keyup.enter="enter($event)"
                        />
                        <a-tag
                          v-else
                          style="
                            background: #f0f5ff;
                            border: 1px #5979f8 dashed;
                            color: #5979f8;
                          "
                          @click="showInput"
                        >
                          <template #icon>
                            <plus-outlined />
                          </template>
                          自定义标签
                        </a-tag>
                      </div>
                    </a-card>
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-col>
      <a-col class="content iss-main" :span="18">
        <div class="Behavior">
          <a-card
            title="行为轨迹"
            :bordered="false"
            headStyle="padding:0;font-weight: bold"
          >
            <div
              style="
                margin: 20px 0;
                max-height: calc(100vh - 269px);
                overflow: auto;
                padding-top: 5px;
                padding-right: 4px;
              "
            >
              <a-timeline>
                <a-timeline-item v-for="item in behaviorList" :key="item">
                  <a-descriptions size="middle">
                    <template v-slot:title> {{ item.actionName }} </template>
                    <a-descriptions-item label="行为时间">
                      {{ item.startTime }}
                    </a-descriptions-item>
                    <a-descriptions-item label="活动名称">
                      {{ item.campaignName }}
                    </a-descriptions-item>
                    <a-descriptions-item label="活动时间">
                      {{ item.campaignStartTime }}
                    </a-descriptions-item>
                    <a-descriptions-item label="内容名称">
                      {{ item.contentName }}
                    </a-descriptions-item>
                    <a-descriptions-item label="内容标签">
                      {{ item.campaignTag }}
                    </a-descriptions-item>
                    <a-descriptions-item label="持续时间">
                      {{ item.duration }}
                      <span v-if="item.duration != null">分钟</span>
                    </a-descriptions-item>
                  </a-descriptions>
                </a-timeline-item>
              </a-timeline>
            </div>
            <a-pagination
              style="margin-bottom: 20px"
              v-model:current="current"
              v-model:pageSize="size"
              :page-size-options="pageSizeOptions"
              show-size-changer
              :total="total"
              @change="handlepageChange"
              @showSizeChange="onShowSizeChange"
            >
            </a-pagination>
          </a-card>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  Avatar,
  Descriptions,
  Row,
  Col,
  Card,
  Tag,
  // Select,
  Timeline,
  // Form,
  // Tooltip,
  // DatePicker,
  Pagination,
  Popconfirm,
} from 'ant-design-vue';
import {
  UserOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import contactApi from '@/api/contact.js';
import { reactive, toRefs, nextTick, ref } from 'vue';
import moment from 'moment';
export default {
  components: {
    AAvatar: Avatar,
    ADescriptions: Descriptions,
    APopconfirm: Popconfirm,
    ARow: Row,
    ACol: Col,
    ACard: Card,
    ATag: Tag,
    UserOutlined,
    PlusOutlined,
    // ASelect: Select,
    ATimeline: Timeline,
    // ATooltip: Tooltip,
    DeleteOutlined,
    APagination: Pagination,
    // AForm: Form,
    // ASpace: Space,
    // AFormItem: Form.Item,
    // ARangePicker: DatePicker.RangePicker,
    ADescriptionsItem: Descriptions.Item,
    ATimelineItem: Timeline.Item,
  },
  setup() {
    const inputRef = ref();
    const route = useRoute();
    const state = reactive({
      contactdetail: {},
      customTag: [],
      normalTag: [],
      inputVisible: false,
      inputValue: '',
      colors: ['#5979F8', '#86D8B5', '#F6C054', '#F27572'],
      behaviorList: [],
      search: {
        actionName: '',
        startTime: '',
        endTime: '',
      },
      TimePick: ['', ''],
      tagdata: {
        contactId: route.params.id,
        isStatic: true,
        tagStatic: true,
        // tagCode: '',
        tagName: '',
      },
      current: 1,
      size: 10,
      total: 0,
      pageSizeOptions: ['5', '10', '20', '30', '40'],
    });
    const statusList = [
      { value: '报名参会', label: '报名参会' },
      { value: '观看直播', label: '观看直播' },
      { value: '会场签到', label: '会场签到' },
      { value: '提交问卷', label: '提交问卷' },
      { value: '查看资料', label: '查看资料' },
      { value: '资料下载', label: '资料下载' },
    ];
    const showInput = () => {
      state.inputVisible = true;
      nextTick(() => {
        inputRef.value.focus();
      });
    };
    const handlepageChange = () => {
      behavior();
    };
    const onShowSizeChange = () => {
      behavior();
    };
    const handleInputConfirm = () => {
      // state.tagdata.tagCode = state.inputValue;
      if (state.inputValue) {
        state.tagdata.tagName = state.inputValue;
        let customTag = state.customTag;
        contactApi.addTag('', state.tagdata).then(res => {
          if (res == true) {
            getTags();
            Object.assign(state, {
              customTag,
              inputVisible: false,
              inputValue: '',
              tagdata: {
                contactId: route.params.id,
                isStatic: true,
                tagStatic: true,
                // tagCode: '',
                tagName: '',
              },
            });
          }
        });
      } else {
        state.inputVisible = false;
      }
    };
    const enter = e => {
      // console.log(e.target.blur());
      e.target.blur();
    };
    const detail = () => {
      const contactId = route.params.id;
      contactApi.getdetail('', contactId).then(res => {
        state.contactdetail = res;
        // console.log(state.contactdetail);
      });
    };
    const behavior = () => {
      const contactId = route.params.id;
      const size = state.size;
      const current = state.current;
      contactApi.getbehavior('', { contactId, size, current }).then(res => {
        state.behaviorList = res.records;
        state.total = parseInt(res.total);
      });
    };
    const getTags = () => {
      const contactId = route.params.id;
      contactApi.getcontactTag('', { contactId }).then(res => {
        state.customTag = res.customTag || [];
        state.normalTag = res.normalTag || [];
      });
    };
    const handleFnOk = () => {
      state.search.startTime = moment(state.TimePick[0])
        .format('YYYY-MM-DD')
        .toString();
      state.search.endTime = moment(state.TimePick[1])
        .format('YYYY-MM-DD')
        .toString();
    };
    const popCancelByDelete = () => {
      console.log('cancel');
    };
    const popConfirmByDelete = item => {
      contactApi.delteTag('', { tagCode: item.tagCode }).then(res => {
        if (res) {
          const a = state.customTag.filter(i => i.id === item.id);
          console.log(a);
          getTags();
        }
      });
    };
    detail();
    behavior();
    getTags();
    return {
      ...toRefs(state),
      detail,
      behavior,
      statusList,
      getTags,
      handleFnOk,
      showInput,
      inputRef,
      enter,
      handleInputConfirm,
      handlepageChange,
      onShowSizeChange,
      popCancelByDelete,
      popConfirmByDelete,
      visibleChange: visible => {
        console.log(visible);
      },
    };
  },
};
</script>
<style lang="less" scoped>
.iss-main {
  display: flex;
  font-size: 12px;
  padding: 0;
  //min-width: 1100px;
  height: calc(100vh - 109px);
  overflow: auto;
}
.iss-main-mr {
  margin-right: 0px;
}
.aside {
  //width: 300px;
  //height: 800px;
  //min-width: 220px;
  text-align: center;
  border-radius: 8px;
  color: #1d2129;
  //background-color: #4358d2;
  /deep/.ant-descriptions-title,
  /deep/.ant-descriptions-item-label,
  /deep/.ant-descriptions-item-content {
    //color: #fff;
    color: #1d2129;
    align-items: center;
  }
  /deep/.ant-descriptions-item-content {
    flex: none;
    display: inline;
  }
  /deep/.ant-descriptions-title {
    padding: 0;
    flex: none;
    //text-align: left;
  }
}

.content {
  flex: 1;
  color: #1d2129;
  .Behavior {
    padding: 0 20px;
    //height: 550px;
  }
  .tag-content {
    margin-right: 8px;
    .tag-delete {
      display: none;
    }
  }
  .tag-content:hover .tag-delete {
    display: inline-block;
  }
  /deep/.ant-card-head {
    border: none;
  }
  /deep/.ant-form-item {
    margin-bottom: 0;
  }
  /deep/.ant-descriptions-title {
    color: #5979f8;
    font-weight: 400;
  }
  /deep/.ant-descriptions-view {
    background-color: #f0f5ff;
    padding: 10px;
  }
  /deep/.ant-timeline-item-tail {
    border-left: 2px dashed #5979f8;
  }
  /deep/.ant-descriptions-middle .ant-descriptions-row > td {
    padding: 10px;
  }
  /deep/.ant-tag {
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    margin-bottom: 8px;
    margin-right: 2px;
    border-radius: 12px;
  }
  /deep/.ant-pagination-item-active {
    background-color: #fff !important;
  }
  /deep/.ant-pagination {
    float: right;
  }
}
.iss-card /deep/ .ant-card-bordered {
  border: none;
}

.iss-card /deep/ .ant-card {
  text-align: left;
}
.header {
  margin: auto;
}
.iss-tab {
  max-height: 230px;
  overflow: auto;
  margin-left: 8px;
}
.iss-main-mr /deep/ .ant-card-head-title {
  color: #1d2129;
}
</style>
